var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("base-search", {
            staticStyle: { display: "inline-block" },
            attrs: {
              searchList: _vm.$sbgllssjLssjSearch,
              listQuery: _vm.listQuery,
              dynamic: true,
              noOperation: true,
            },
            on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bg-white", attrs: { id: "table_box_height" } },
        [
          _c("basic-table", {
            key: _vm.nowtimer,
            attrs: {
              tableHeight: _vm.tableHeight,
              tableHeaderList: _vm.firstHeaderList,
              tableData: _vm.tableData,
              listQuery: _vm.listQuery,
              otherColumn: _vm.otherColumn,
              tableTotal: _vm.tableTotal,
              hasSelection: true,
            },
            on: {
              pagination: _vm.handleCurrentChange,
              tableRowClick: _vm.tableRowClick,
              handleSelectionChange: _vm.handleSelectionChange,
            },
            scopedSlots: _vm._u([
              {
                key: "imgSrc",
                fn: function (row) {
                  return [
                    row.rowData.screenshotPath
                      ? _c("el-image", {
                          ref: "myImg",
                          staticStyle: {
                            width: "24px",
                            height: "24px",
                            cursor: "pointer",
                          },
                          attrs: { src: require("@/assets/img/thumbnail.png") },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.viewImg($event, row.rowData)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.imgDialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imgDialogVisible = $event
            },
          },
        },
        [
          _c("el-image", { attrs: { src: _vm.previewImgSrc } }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.imgDialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }